
import './App.css';

function App() {
  return (
    <div className="App">
      <div className="background-comp">
        <video loop muted autoPlay="autoplay">
            <source src="/static/media/aurora_background_1080p.mp4" type="video/mp4"></source>
            Your browser does not support the video tag.
        </video>
        <div className="overlay top-left margin-5perc max-size-20perc">
            <img src="/static/media/KDL-Moon-Logo.png" alt="Logo"></img>
        </div>
        <div className="footer-graphic depth-glow">
          <p id="site-title">Kevin Daniel Lee</p>
        </div>
      </div>
    </div>
    
  );
}

export default App;
